<template>
    <div>
        <topBarY />
        <div class="bg">
            <img src="../../assets/common/pic/bgs.jpg" alt="">
        </div>
        <div class="switchBar">
            <div class="tab product" :class="{ active: switchIndex == 1 }" @click="switchClick(1)">服务&维修</div>
            <div class="tab service" :class="{ active: switchIndex == 2 }" @click="switchClick(2)">资源中心</div>
        </div>
        <div class="line"></div>
        <div class="switchContiner">
            <div class="continer serviceContiner" v-if="switchIndex == 1">
                <div class="service">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司秉承专业、周到、热情的经营理念及服务态度
                    在为用户提供的专业规划设计中，会充分注重营收策略，
                    充分体现“以客户为主导”的思想。特别重视前期的数据搜集整理、同行探讨、网络设计、测试以及与用户充分的交流，
                    最大限度地保障项目的成功实施，帮助用户快速、经济地实现无线网络传输、覆盖和用户接入及相关应用，并承诺相关：
                </div>
                <div class="serviceli first">提供客户的需求沟通及方案设计</div>
                <div class="serviceli">提供工程师客户到现场环境的勘察</div>
                <div class="serviceli">提供客户的相关的样品借测</div>
                <div class="serviceli">提供客户产品技术安装培训</div>
                <div class="serviceli">提供相关产品硬件一年质保</div>
                <div class="serviceli">需要维修服务？请联系我们的售后代表</div>
                <div class="problem">遇到问题？请联系我们：</div>
                <div class="num first">销售咨询：sales@linkom.com.cn</div>
                <div class="num">技术咨询：support@linkom.com.cn</div>
                <div class="num">产品支持：4000-975717 (工作时间 9：00 am- 17:30 pm)</div>
            </div>
            <div class="continer resourcesContiner" v-if="switchIndex == 2">
                <div class="plan first" @click="engineeringClick">
                    <img src="../../assets/support/engineering.jpg" alt="">
                    <span>工程案例实装</span>
                </div>
                <div class="plan" @click="guideClick">
                    <img src="../../assets/support/guide.jpg" alt="">
                    <span>产品安装指导</span>
                </div>
                <div class="plan" @click="loginClick">
                    <img src="../../assets/support/document.jpg" alt="">
                    <span>产品技术文档下载</span>
                </div>
            </div>
        </div>
        <baseBar />
    </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
    name: 'support',
    components: {
        topBarY,
        baseBar
    },
    data() {
        return {
            switchIndex: 1,
            proIndex: 1
        }
    },
    methods: {
        switchClick(index) {
            this.switchIndex = index
        },
        proClick(index) {
            this.proIndex = index
        },
        engineeringClick() {
            this.$router.push('engineering')
        },
        guideClick() {
            this.$router.push('guide')
        },
        loginClick() {
            this.$router.push('login')
        }
    },
    created() {
        this.$nextTick(() => {
            // 1.禁用右键菜单
            document.oncontextmenu = new Function("event.returnValue=false");
            // 2.禁用鼠标选中
            document.onselectstart = new Function("event.returnValue=false");
            // 3.禁止键盘F12键
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                    e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        });
    }
}
</script>

<style scoped>
.bg {
    position: relative;
    width: 1920px;
    height: 480px;
    overflow: hidden;
}

.bg img {
    position: absolute;
    top: -100px;
    width: 1920px;
}

.switchBar {
    height: 70px;
    background-color: #fff;
    text-align: center;
}

.switchBar .tab {
    display: inline-block;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    background-color: #c3a35d;
    color: #f5f5f5;
    font-family: 'Microsoft Yahei';
    font-size: 16px;
    line-height: 40px;
}

.line {
    margin: 0 auto;
    width: 1400px;
    height: 1px;
    background-color: #c3a35d;
}

.switchContiner {
    width: 1920px;
    padding: 20px;
    background-color: #f7f7f7;
}

/* 维修&保修 */
.switchContiner .serviceContiner {
    margin: 0 auto;
    width: 1400px;
    height: 620px;
    border-radius: 5px;
    background-color: #fff;
}

.switchContiner .serviceContiner .service {
    margin: 0 auto;
    padding-top: 40px;
    width: 1034px;
    font-size: 18px;
    line-height: 40px;
}

.switchContiner .serviceContiner .serviceli {
    margin-left: 216px;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
}

.switchContiner .serviceContiner .first {
    margin-top: 20px;
}

.switchContiner .serviceContiner .problem {
    margin-top: 40px;
    margin-left: 180px;
    font-size: 18px;
}

.switchContiner .serviceContiner .num {
    margin-left: 216px;
    font-size: 16px;
    line-height: 30px;
}

/* 资源中心 */
.switchContiner .resourcesContiner {
    margin: 0 auto;
    width: 1400px;
    height: 300px;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
}

.switchContiner .resourcesContiner .plan {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 400px;
    height: 220px;
    background-color: #f7f7f7;
    margin-top: 40px;
    margin-right: 40px;
    text-align: left;
    font-size: 16px;
}

.switchContiner .resourcesContiner .first {
    margin-left: 40px;
}

.resourcesContiner .plan:hover {
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    transform: translate3d(0, -2px, 0);
    transition: all 0.2s linear;
}

.resourcesContiner .plan span {
    position: absolute;
    left: 10px;
    bottom: 5px;
}

.switchContiner .continer .plan img {
    width: 400px;
    height: 220px;
}
</style>
